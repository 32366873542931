






import { Component, Mixins } from 'vue-property-decorator'

import MedicalPatientForm from '@/partials/forms/Medical/PatientForm.vue'
import CenteredMedium from '@/layouts/Panels/CenteredMedium66.vue'
import FlashMessageMixin from '@/mixins/flashMessageMixin'

@Component({
  components: {
    CenteredMedium,
    MedicalPatientForm
  }
})
export default class MedicalPatientRegistrationForm extends Mixins(FlashMessageMixin) {
}
